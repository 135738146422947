//Search History Start 9-c
.main-search-history-list-grid {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        // padding: 0;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        // padding: 0;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Search History End

//viww-product Start 10-c
.main-view-product-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        // word-break: break-all !important;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(10) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 15%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(10) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//viww-product Start 11-c

//Shopping Cart Start 7-c
.main-shopping-cart-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        // padding: 0;
                    }
                    &:nth-child(3) {
                        width: 20%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: end;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 20%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        // padding: 0;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: end;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Shopping Cart End

//Exception Start 7-c
.main-exception-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                    }
                    &:nth-child(5) {
                        width: 30%;
                    }
                    &:nth-child(6) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        white-space: normal !important;
                        // word-break: break-all !important;
                    }
                    &:nth-child(5) {
                        width: 30%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(6) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Exception End

//userActivity Start 9-c
.main-user-activity-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        // padding: 0;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 20%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        // padding: 0;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//userActivity End

//rfq-history Start 6-c
.main-rfq-history-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//rfq-history End

//Order History Start 10-c
.main-order-history-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        // padding: 0;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: end;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(10) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        // padding: 0;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 15%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        white-space: unset !important;
                    }
                    &:nth-child(6) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: end;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(10) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Order History End

//Product history Start 5-c
.main-product-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 40%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 40%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Product history End

//User list Start 9-c
.main-User-list-grid {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        // padding: 0;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 420px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 20%;
                        // padding: 0;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(6) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//User list End

//Admin Users Start 3-c
.main-Admin-user-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 35%;
                    }

                    &:nth-child(2) {
                        width: 35%;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 35%;
                    }

                    &:nth-child(2) {
                        width: 35%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Admin Users End

//Contact Start 4-C
.main-contact-us-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Contact End 4-C

//Audit Log History Start  8-c
.main-audit-history-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                    &:nth-child(4) {
                        width: 15%;
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                    &:nth-child(8) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 15%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                    &:nth-child(4) {
                        width: 15%;
                    }
                    &:nth-child(5) {
                        width: 15%;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                    &:nth-child(7) {
                        width: 10%;
                    }
                    &:nth-child(8) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Audit Log History End 8-c

//Companies start 2-c
.main-companies-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 50%;
                    }

                    &:nth-child(2) {
                        width: 50%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 50%;
                    }

                    &:nth-child(2) {
                        width: 50%;
                    }
                }
            }
        }
    }
}
//Companies end 2-c

//View Ip Address Strat 4-c
.mian-view-ip-address-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//View Ip Address end 4-c

//Block Ip Address Start 3-c
.main-block-ip-address-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 35%;
                    }

                    &:nth-child(2) {
                        width: 35%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 35%;
                    }

                    &:nth-child(2) {
                        width: 35%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Block Ip Address End

//Email Template Start 4-c
.main-email-temp-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Email Template End 4-c

//Digest Email Start 4-c
.main-Digest-Email-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Digest Email End 4-c

//Informatics Configurations Start 3-c
.main-informatics-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 35%;
                    }

                    &:nth-child(2) {
                        width: 35%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 35%;
                    }

                    &:nth-child(2) {
                        width: 35%;
                        white-space: normal !important;
                        word-break: break-all !important;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(3) {
                        width: 30%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Informatics Configurations End

//Data Import List Start 6-c
.main-data-import-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 30%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 405px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 30%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Data Import List End

//Dashboard Order History Start 10-c
.main-dash-order-history-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #FFF9E5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        // padding: 0;
                    }
                    &:nth-child(3) {
                        width: 15%;
                    }
                    &:nth-child(4) {
                        width: 10%;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: end;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(10) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 620px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }

                    &:nth-child(2) {
                        width: 10%;
                        // padding: 0;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 15%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(4) {
                        width: 10%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(5) {
                        width: 10%;
                    }
                    &:nth-child(6) {
                        width: 5%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(7) {
                        width: 10%;
                        display: flex;
                        justify-content: end;
                    }
                    &:nth-child(8) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(9) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(10) {
                        width: 10%;
                    }
                }
            }
        }
    }
}
//Dashboard Order History End

//rfq-history Start 6-c
.main-dash-rfq-history-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #FFF9E5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 620px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 20%;
                    }

                    &:nth-child(2) {
                        width: 20%;
                    }
                    &:nth-child(3) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 20%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(5) {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(6) {
                        width: 10%;
                    }
                }
            }
        }

        .no-record-width {
            width: 130%;
            font-size: 20px;
        }
    }
}
//rfq-history End

//Contact Start 5-C
.main-dash-contact-us-list {
    .table-container {
        overflow-x: auto; /* Enable horizontal scrolling */
        max-width: 100%; /* Ensure the table container fills its parent */
    }
    table {
        overflow: hidden;
        width: 100%;
        caption-side: bottom;
        border-collapse: collapse;
        thead {
            display: flex;
            width: 100%;
            color: black !important;
            background-color: #fff9e5 !important;
            tr {
                align-items: center;
                display: flex;
                width: 100%;
                th {
                    padding-left: 0;
                    border: none;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        tbody {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 620px);
            overflow: hidden;
            overflow-y: auto;
            width: 100%;
            tr {
                display: flex;
                td {
                    padding-left: 0;
                    &:nth-child(1) {
                        width: 25%;
                    }

                    &:nth-child(2) {
                        width: 25%;
                        white-space: normal !important;
                        word-break: break-all !important;
                    }
                    &:nth-child(3) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                    &:nth-child(4) {
                        width: 25%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
//Contact End 4-C
