// Typography
.h1 {
    @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}


// Forms
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}



.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply text-sm text-slate-800 bg-white border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    // @apply rounded;

}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply leading-5 py-2 border-slate-200 hover:border-slate-300 focus:border-indigo-300;
}

.form-input,
.form-textarea {
    @apply placeholder-slate-400;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-indigo-500 border border-slate-300;
}

// Buttons
.btn,
.btn-lg,
.btn-sm,
.btn-xs {
    @apply font-medium text-sm inline-flex items-center justify-center border border-transparent leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
    @apply px-4 py-2;
    // @apply px-3 py-2;
}

.btnn {
    // @apply px-1 py-2;
    @apply px-5 py-2;
}

.btn-lg {
    @apply px-4 py-3;
}

.btn-sm {
    @apply px-2 py-1;
}

.btn-xs {
    @apply px-2 py-0.5;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.backorder {
    margin-right: -4px;
}

.bg-color-1 {
    color: #1e293b;
    // color: #fff;
}

.minWidth {
    min-width: 8rem;
}

.card-absolute {
    position: absolute;
    top: -25px;
    background: #fff;
    border-radius: 15px;
    padding: 13px;
    left: 39px;
    box-shadow: 0px 5px 10px -7px #000;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.card-absolute svg path {
    fill: var(--commanColoricon) !important;

}

.card-absolute i {
    color: var(--commanColoricon) !important;

}

.margin-left-25 {
    margin-left: 25%;
}

.ui-width {
    max-width: 50%;
    //   height: 50%;
}


// Card Section Start
.card-part {
    overflow: hidden;
    display: flex;
    position: relative;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background: #fff;
    box-shadow: 0px 3px 24px -20px var(--commanColoricon);
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;

    &:hover {
        box-shadow: 0px 3px 24px -10px var(--commanColoricon);
    }

    &::after {
        height: 120px;
        width: 120px;
        transform: rotate(40deg);
        top: -35px;
        right: -35px;
        border-radius: 1.75rem;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgb(255 180 0 / 7%) 0%, rgb(255 180 0 / 6%) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26ffffff', endColorstr='#00ffffff', GradientType=1);
    }

    .card-body {
        padding: 30px 35px;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            min-width: 60px;
            line-height: 54px;
            border-radius: 50%;
            background: rgb(255 193 7 / 14%);
            text-align: center;

            svg {
                height: 30px;
                width: 30px;
                fill: var(--commanColoricon);

                path {
                    fill: var(--commanColoricon);
                }
            }

            i {
                font-size: 32px;
                color: var(--commanColoricon);
            }
        }

        .invoice-num {
            font-size: 30px;
            line-height: 42px;
            // margin-bottom: 10px;
            color: #ffc107;
        }

        .fs-18 {
            font-size: 15px !important;
            line-height: 1.5;
            color: #000;
        }
    }

    &::after {
        position: absolute;
        content: "";
    }


}

.counter-num {
    margin-right: 30px;
    font-size: 30px;

}

.title-counter {

    font-size: 20px;
    color: #1e293b;
    padding-top: 20px;
    padding-left: 40px;

}

// Card Section End

@media (max-width: 767px) {
    .SerchBox-center1 {
        margin-left: 4.5rem;

        .ui-width {
            max-width: 500px;
        }
    }

    .ml-shoppingCart {
        margin-left: 5rem
    }
}