@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@tailwind base;
@tailwind components;
@tailwind utilities;
// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
// @import 'additional-styles/toggle-switch.scss';
// @import 'additional-styles/flatpickr.scss';
@import "additional-styles/theme.scss";
@import "./variables.scss";

.mt-top-0{
    margin-top: 0 !important;
}

.warning-dot {
    position: absolute;
    width: 7px;
    height: 7px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 5px;
    background-color: #facea8;
}

.warning-body {
    position: absolute;
    width: 5px;
    height: 47px;
    left: 50%;
    top: 6px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #facea8;
}

.captchaparent {
    // background: #000 !important;
    width: 10%;
    padding: 30px 30px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
// .captch-img img{
//     background-color: #fff2aa !important;
// }
.new-style {
    color: #6e6e6e;
    font-weight: 400;
    text-shadow: none;
    font-size: 14px;
}

.height-width-canvas {
    canvas {
        width: 55% !important;
        height: 55% !important;
        margin: 0 auto !important;
    }
}

.radar-chart-sec{
    canvas {
        width: 85% !important;
        height: 85% !important;
        margin: 0 auto !important;
    }
}
.Charts-date input {
    height: 35px;
    width: 110px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    // padding-left: 11px;
    // min-width: 247px;
    outline: none;
    // padding-right: 22px;
    margin-right: 17px;
}

.sticky-container {
    height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: auto;
}

.white-space {
    white-space: nowrap;
}

.xlsx-hover {
    margin-top: -20px;
    margin-left: 40px;
}

.not-allowed {
    padding: 10px 20px;
    background-color: #1e293b;
    color: white;
    border: none;
    border-radius: 2px;
    cursor: not-allowed !important;
}

.custom-datepicker {
    border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
    margin-right: 13px;
}

.center-div {
    box-shadow: 0 0px 14px -7px #091934 !important;
}

.xlsx-file {
    height: 15px;
    width: 50px;
}


.chart-min-height {
    height: 100% !important;
}

.chart-min-height {
    canvas {
        height: 100%;
        // width: 1300px !important;
        // width: 500px !important;
    }
}

.h-screen {
    height: 100vh !important;
}

.border-radius-10 {
    border-radius: 10px;
}

.sidebar-expanded .lg\:sidebar-expanded\:\!w-64 {
    width: 18rem !important;
}

.background-hover-sidebar {
    &:hover {
        border-radius: 10px;
        background: var(--commanColorHover);
    }
}

.sidebar-new-shadow {
    // box-shadow: 0px 0px 10px 0px #000000;
}

.pad-login-add {
    padding: 50px 50px;
}

.login-back-color {
    background: #ffc1071a;
}

.min-width-130 {
    min-width: 130px;
}

.login-card {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
}

.pad-right-20 {
    padding-right: 20px;
}

.card-bg-light {
    background: #fff;
}

.sidebar-height {
    height: 100%;
}

.main-sideber-padding {
    margin: 20px 20px 20px 20px;
}

.main-sideber-padding-dashborad {
    margin: 20px 0 20px 20px;
}

.darktext {
    color: var(--darkcommanColor);
}

.commanColorbtn {
    background: var(--commanColoricon) !important;

    &:hover {
        color: #000 !important;
    }
}

.icon-new-yellow-color {
    i {
        color: var(--commanColorHover);
    }
}

.new-padding-sidebar {
    padding: 10px;
    font-size: 15px;
}

.text-slate-400 {

    // color: #fff;
    &:hover {
        color: var(--textcommanColorHover) !important;
    }
}

.font-weight-600 {
    font-weight: 600;
}

// .text-slate-200 {
//     font-weight: 600;
//     color: #000;
// }
// .text-slate-400 {
//     font-weight: 600;
//     color: #000;
// }

.table-height-fix {
    height: 403px;
    overflow: auto;
}

.table-4-height {
    height: 403px;
}

.text-dark {
    color: #000 !important;
    font-weight: 600 !important;
}

.font-weight-400 {
    font-weight: 400;
}

.new-background-color {
    background: var(--commanColorLight);
}

.sidemenucolor-thead {
    background: #fff9e5;
    color: #000;
}

.table-tr-width table thead tr th td div,
.table-tr-width table tbody tr td {
    width: 20%;
}

.shopping_details {
    display: flex;
    justify-content: space-between;
}

.username-text {
    color: black !important;
    cursor: pointer !important;
}

.reports-button {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 2.5rem;
}

.date_select {
    font-weight: 600;
}

.shopping-search-bar {
    display: flex !important;
}

.shopping-search-bar-flex {
    display: flex !important;
    justify-content: end !important;
}

table tbody .table-tr-custom tr td {
    text-align: end;
}

.dashboard-date-range {
    input {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        padding-right: 35px;
        min-width: 220px;
        outline: none;
        border-color: rgb(226 232 240 / var(--tw-border-opacity));

        &:focus {
            outline: none;
            box-shadow: none;
            border-color: #091934;
        }
    }

    .text-blue-500,
    .text-blue-600 {
        color: var(--commanColoricon);
    }

    .text-blue-500 {
        font-weight: bold !important;
    }

    .hover\:text-blue-700:hover {
        color: #091934;
    }

    .bg-blue-500 {
        background-color: #091934;
    }

    .striped {
        overflow-x: auto;
    }

    .bg-blue-100,
    .hover\:bg-gray-100:hover {
        background-color: #5b85c90f;
    }

    button {
        padding: 0 10px;
    }

    .w-12 {
        width: 35px;
    }

    .h-12 {
        height: 35px;
    }

    .lg:w-10 {
        width: 35px;
    }

    .lg:h-10 {
        height: 35px;
    }

    .flex-none {
        display: flex;
    }
}

.fixed_header {
    tbody {
        display: block;
        max-height: 390px;
        overflow: auto;
        width: 100%;
    }
}

.userdetalgap {
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
}

.fixed_header_dashboard {
    display: block;
    max-height: 450px;
    overflow: auto;
    width: 100%;
    padding: 0px !important;
    margin-top: 0px !important;
}

.fixed_header_dashboard_pricelist {
    display: block;
    overflow: auto;
    width: 100%;
    padding: 0px !important;
    margin-top: 0px !important;
}

.shadowColor {
    border-radius: 0px !important;
    // padding: 0px !important;
    margin-top: 0px !important;
}

.font-medium {
    font-weight: 500;
}

// table-tr-rfq

.table-tr-rfq table thead tr th:nth-child(1),
.table-tr-rfq table tbody tr td:nth-child(1) {
    //text-align: left;
    width: 20%;
}

.table-tr-rfq table thead tr th:nth-child(1) div {
    text-align: left;
}

.table-tr-rfq table thead tr th:nth-child(2),
.table-tr-rfq table tbody tr td:nth-child(2) {
    text-align: left;
    width: 40%;
}

.table-tr-rfq table thead tr th:nth-child(2) div {
    text-align: left;
}

.table-tr-rfq table thead tr th:nth-child(3),
.table-tr-rfq table tbody tr td:nth-child(3) {
    text-align: left;
    width: 20%;
}

.table-tr-rfq table thead tr th:nth-child(3) div {
    text-align: left;
}

.table-tr-rfq table thead tr th:nth-child(4),
.table-tr-rfq table tbody tr td:nth-child(4) {
    text-align: center;
    width: 10%;
}

.table-tr-rfq table thead tr th:nth-child(4) div {
    text-align: left;
}

.table-tr-rfq table thead tr th:nth-child(5),
.table-tr-rfq table tbody tr td:nth-child(5) {
    text-align: center;
    width: 10%;
}

.table-tr-rfq table thead tr th:nth-child(5) div {
    text-align: center;
}

.table-tr-search table thead tr th:nth-child(3) div,
.table-tr-search table tbody tr td:nth-child(3) {
    text-align: center;
}

// search-history-table
.search-history-table table thead tr th:nth-child(1),
.search-history-table table tbody tr td:nth-child(1) {
    text-align: left;
    width: 15%;
}

.search-history-table table thead tr th:nth-child(2),
.search-history-table table tbody tr td:nth-child(2) {
    text-align: left;
    width: 20%;
}

.search-history-table table thead tr th:nth-child(3),
.search-history-table table tbody tr td:nth-child(3) {
    text-align: left;
    width: 15%;
}

.search-history-table table thead tr th:nth-child(3) div {
    text-align: left;
}

.search-history-table table thead tr th:nth-child(4),
.search-history-table table tbody tr td:nth-child(4) {
    text-align: center;
    width: 20%;
}

.search-history-table table thead tr th:nth-child(4) div {
    text-align: center;
}

.search-history-table table thead tr th:nth-child(5),
.search-history-table table tbody tr td:nth-child(5) {
    text-align: center;
    width: 20%;
}

.search-history-table table thead tr th:nth-child(7),
.search-history-table table tbody tr td:nth-child(7) {
    text-align: center;
    // width: 20%;
}

.search-history-table table thead tr th:nth-child(5) div {
    text-align: center;
}

// .table-tr-search2 table thead tr th:nth-child(5) div,
// .table-tr-search2 table tbody tr td:nth-child(5) {
//     text-align: center;
// }

// view-product-table

.view-product-table table thead tr th:nth-child(1),
.view-product-table table tbody tr td:nth-child(1) {
    text-align: left;
    width: 10%;
}

.view-product-table table thead tr th:nth-child(2),
.view-product-table table tbody tr td:nth-child(2) {
    // text-align: center;
    width: 10%;
}

.view-product-table table thead tr th:nth-child(2) div {
    // text-align: center;
    font-style: 700;
}

.view-product-table table thead tr th:nth-child(3),
.view-product-table table tbody tr td:nth-child(3) {
    text-align: left;
    width: 10%;
}

.view-product-table table thead tr th:nth-child(4),
.view-product-table table tbody tr td:nth-child(4) {
    text-align: center;
    width: 10%;
}

.view-product-table table thead tr th:nth-child(5),
.view-product-table table tbody tr td:nth-child(5) {
    text-align: center;
    width: 20%;
}

.view-product-table table thead tr th:nth-child(6),
.view-product-table table tbody tr td:nth-child(6) {
    text-align: center;
    // width: 10%;
}

// .view-product-table table thead tr th:nth-child(7),
// .view-product-table table tbody tr td:nth-child(7) {
//     text-align: center;
//     width: 10%;
// }
// .view-product-table table thead tr th:nth-child(8),
// .view-product-table table tbody tr td:nth-child(8) {
//     text-align: center;
//     width: 10%;
// }
.view-product-table table thead tr th:nth-child(4) div {
    text-align: center;
}

.view-product-table table thead tr th:nth-child(5) div {
    text-align: center;
}

.view-product-table table thead tr th:nth-child(6) div {
    text-align: center;
}

.view-product-table-1 table thead tr th:nth-child(6) div {
    text-align: end;
}

// .view-product-table table thead tr th:nth-child(7) div {
//     text-align: center;
// }
// .view-product-table table thead tr th:nth-child(8) div {
//     text-align: center;
// }

.table-tr-pvbu table thead tr th:nth-child(2) div,
.table-tr-pvbu table tbody tr td:nth-child(2) {
    text-align: center;
}

.table-tr-pvbu1 table thead tr th:nth-child(3) div,
.table-tr-pvbu1 table tbody tr td:nth-child(3) {
    text-align: center;
}

// Order History
.order-history-table table thead tr th:nth-child(1),
.order-history-table table tbody tr td:nth-child(1) {
    //text-align: left;
    width: 40%;
}

.order-history-table table thead tr th:nth-child(2),
.order-history-table table tbody tr td:nth-child(2) {
    text-align: left;
    width: 10%;
}

.order-history-table table thead tr th:nth-child(3),
.order-history-table table tbody tr td:nth-child(3) {
    text-align: left;
    width: 10%;
}

.order-history-table table thead tr th:nth-child(4),
.order-history-table table tbody tr td:nth-child(4) {
    text-align: center;
    width: 10%;
}

.order-history-table table thead tr th:nth-child(4) div {
    text-align: center;
}

.order-history-table table thead tr th:nth-child(5),
.order-history-table table tbody tr td:nth-child(5) {
    text-align: center;
    width: 20%;
}

.order-history-table table thead tr th:nth-child(5) div {
    text-align: center;
}

.order-history-table table thead tr th:nth-child(6),
.order-history-table table tbody tr td:nth-child(6) {
    text-align: center;
    width: 10%;
}

.order-history-table table thead tr th:nth-child(6) div {
    text-align: center;
}

.table-tr-pvbu3 table thead tr th:nth-child(5) div,
.table-tr-pvbu3 table tbody tr td:nth-child(5) {
    text-align: center;
}

.table-tr-custom:nth-child(odd) {
    background: #fff;
    border-bottom: 1px solid #00000014;

    &:last-child {
        border-bottom: none;
    }
}

.Price-for {
    .table-tr-custom {
        td {
            &:nth-child(7) {
                text-align: right;
            }
        }
    }
}

.price_formate {
    .table-tr-custom {
        td {
            &:nth-child(4) {
                text-align: center;
            }
        }
    }
}

.price_formate {
    .table-tr-custom {
        td {
            &:nth-child(5) {
                text-align: center;
            }
        }
    }
}

.Price-forr {
    .table-tr-custom {
        td {
            &:nth-child(7) {
                text-align: center;
            }
        }
    }
}

.breack-down-serch-history {
    .table-tr-custom {
        td {
            &:nth-child(3) {
                word-break: break-all !important;
                white-space: normal;
            }
        }
    }
}

.Exception-break {
    .table-tr-custom {
        td {
            &:nth-child(3) {
                width: 10% !important;
            }
        }
    }
}

.Exception-break {
    .table-tr-custom {
        td {
            &:nth-child(6) {
                text-align: center !important;
            }
        }
    }
}

.Exception-break {
    .table-tr-custom {
        td {
            &:nth-child(4) {
                white-space: normal;
                text-align: left !important;
            }
        }
    }
}

.view-product {
    .table-tr-custom {
        td {
            &:nth-child(5) {
                word-break: unset !important;
                white-space: normal;
            }
        }
    }
}

.max-w-9xl {
    max-width: 103rem !important;
}

.sync-button {
    padding: 10px 20px;
    background-color: #1e293b;
    color: white;
    border: none;
    border-radius: 2px;
    cursor: pointer !important;
}

.sync-button:hover {
    background-color: #3c5e96;
}

.table-tr-custom:nth-child(even) {
    background: #fff;
    border-bottom: 1px solid #00000014;

    &:last-child {
        border-bottom: none;
    }
}

// product-history-table
.product-history-table table thead tr th:nth-child(1),
.product-history-table table tbody tr td:nth-child(1) {
    text-align: center;
    width: 10%;
}

.product-history-table table thead tr th:nth-child(1) div {
    text-align: center;
}

.product-history-table table thead tr th:nth-child(2),
.product-history-table table tbody tr td:nth-child(2) {
    text-align: center;
    width: 15%;
}

.product-history-table table thead tr th:nth-child(2) div {
    text-align: center;
}

.product-history-table table thead tr th:nth-child(3),
.product-history-table table tbody tr td:nth-child(3) {
    text-align: center;
    width: 10%;
}

.product-history-table table thead tr th:nth-child(3) div {
    text-align: center;
}

.product-history-table table thead tr th:nth-child(4),
.product-history-table table tbody tr td:nth-child(4) {
    text-align: center;
    width: 55%;
}

.product-history-table table thead tr th:nth-child(4) div {
    text-align: center;
}

.product-history-table table thead tr th:nth-child(5),
.product-history-table table tbody tr td:nth-child(5) {
    text-align: left;
    width: 10%;
}

.product-history-table table thead tr th:nth-child(5) div {
    text-align: center;
}

// user-list-table

.user-list-table table thead tr th:nth-child(1),
.user-list-table table tbody tr td:nth-child(1) {
    //text-align: left;
    width: 20%;
    white-space: inherit !important;
}

.user-list-table table thead tr th:nth-child(1) div {
    text-align: left;
}

.user-list-table table thead tr th:nth-child(2),
.user-list-table table tbody tr td:nth-child(2) {
    text-align: left;
    width: 30%;
}

.user-list-table table thead tr th:nth-child(2) div {
    text-align: left;
}

.user-list-table table thead tr th:nth-child(3),
.user-list-table table tbody tr td:nth-child(3) {
    text-align: left;
    width: 30%;
    white-space: inherit !important;
}

.user-list-table table thead tr th:nth-child(3) div {
    text-align: left;
}

.user-list-table table thead tr th:nth-child(4),
.user-list-table table tbody tr td:nth-child(4) {
    text-align: left;
    width: 10%;
}

.user-list-table table thead tr th:nth-child(4) div {
    text-align: left;
}

.user-list-table table thead tr th:nth-child(5),
.user-list-table table tbody tr td:nth-child(5) {
    text-align: center;
    width: 10%;
}

.user-list-table table thead tr th:nth-child(5) div {
    text-align: center;
}

// admin-user-list-table
.admin-user-list-table table thead tr th:nth-child(1),
.admin-user-list-table table tbody tr td:nth-child(1) {
    text-align: left;
    width: 40%;
}

.admin-user-list-table table thead tr th:nth-child(1) div {
    text-align: left;
}

.admin-user-list-table table thead tr th:nth-child(2),
.admin-user-list-table table tbody tr td:nth-child(2) {
    text-align: left;
    width: 30%;
}

.admin-user-list-table table thead tr th:nth-child(2) div {
    text-align: left;
}

.admin-user-list-table table thead tr th:nth-child(3),
.admin-user-list-table table tbody tr td:nth-child(3) {
    text-align: left;
    width: 30%;
}

.admin-user-list-table table thead tr th:nth-child(3) div {
    text-align: left;
}

// shopping-cart-table-sec
.shopping-cart-table-sec .card_details span {
    min-width: 75px;
    display: inline-block;
}

.shopping-cart-table-sec table thead tr th:nth-child(1),
.shopping-cart-table-sec table tbody tr td:nth-child(1) {
    text-align: left;
    width: 20%;
}

.shopping-cart-table-sec table thead tr th:nth-child(1) div {
    text-align: left;
}

.shopping-cart-table-sec table thead tr th:nth-child(2),
.shopping-cart-table-sec table tbody tr td:nth-child(2) {
    text-align: left;
    width: 45%;
}

.shopping-cart-table-sec table thead tr th:nth-child(2) div {
    text-align: left;
}

.shopping-cart-table-sec table thead tr th:nth-child(3),
.shopping-cart-table-sec table tbody tr td:nth-child(3) {
    text-align: center;
    width: 12%;
}

.shopping-cart-table-sec table thead tr th:nth-child(3) div {
    text-align: center;
}

.shopping-cart-table-sec table thead tr th:nth-child(4),
.shopping-cart-table-sec table tbody tr td:nth-child(4) {
    text-align: center;
    width: 11%;
}

.shopping-cart-table-sec table thead tr th:nth-child(4) div {
    text-align: center;
}

.shopping-cart-table-sec table thead tr th:nth-child(5),
.shopping-cart-table-sec table tbody tr td:nth-child(5) {
    text-align: right;
    width: 12%;
}

.shopping-cart-table-sec table thead tr th:nth-child(5) div {
    text-align: right;
}

.shopping-cart-table-sec .cart-image1 {
    position: relative;
}

.shopping-cart-table-sec .product-availability {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
}

/* Custom-Scrollbar Start */
::-webkit-scrollbar {
    width: 4px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--commanColoricon);
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: #8db5e0;
// }

/* Custom-Scrollbar End */
.sidemenucolor {
    --tw-bg-opacity: 1;
    background-color: #fff;
}

.demo-color {
    color: #003ca3;
    font-size: 1.8rem;
}

.demopage img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bcolor {
    background-color: #091934;
    // nd-color: #091934;
    color: #fff;
}

// Shopping cart Start
.cart_item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px 0;
}

.cart-image1 img {
    max-width: 100%;
    margin-right: 20px;
}

.product-availability {
    margin: 10px auto 0;
    margin-left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 95px;
    z-index: 1;
}

.stockStatus {
    font-weight: 500;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
}

.stockStatus i {
    margin-top: 1px !important;
    padding-left: 10px !important;
    padding-right: 0.5rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}

.product-availability .stockStatus.in-stock {
    background: #5aab19;
    color: #fff;
}

.q_section {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
    min-width: 50%;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-title {
    color: #332d97;
    font-weight: bold;
}

.card_details p {
    white-space: nowrap;
    margin-bottom: 5px;
}

.card_details p label {
    display: inline-block;
    font-weight: 600;
    color: #000;
    position: relative;
    text-transform: capitalize;
}

.card_details span {
    font-weight: bold;
    padding: 0;
    font-size: 15px;
}

.card_details .ml-auto {
    margin-left: auto !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.btnsign {
    padding: 11px;
}

.float-right1 {
    // float: center;
    justify-content: center;
    // margin-top: 10px;
    // margin: 0.5rem 0rem 0rem -19rem;
}

.dashboard_displayAll {
    font-size: inherit !important;
    padding-right: 1rem;
}

.float-right2 {
    display: flex;
    justify-content: end;
}

.step {
    color: #6366f1;
}

.buttonDataImport {
    min-width: 4rem;
}

.marginRight {
    margin-right: 1.5rem;
}

.overflow {
    position: absolute;
}

.product-quantity {
    border-radius: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.product-quantity input {
    margin: 0 0.7rem;
}

.image {
    max-width: 73%;
    height: auto;
}

.justify-between1 {
    justify-content: center;
}

.rfq-detail-list {
    .email-mark-icon {
        border-radius: 50px 0px 0px 50px;
        -webkit-border-radius: 50px 0px 0px 50px;
        -moz-border-radius: 50px 0px 0px 50px;
        -ms-border-radius: 50px 0px 0px 50px;
        -o-border-radius: 50px 0px 0px 50px;
    }
}

input,
select {
    height: 44px;
}

.ExpoBtn {
    background: rgb(230, 230, 230);
    padding: 13px 30px;
    margin-bottom: 10px;
}

.shopping-price-text Table {
    text-align: end !important;
}

.IpInput {
    border: 1px solid black;
    padding: 0 10px;
    border-radius: 0px !important;
    height: 40px;
}

.managereports {
    // display: flex;
    justify-content: space-around;
}

.unauthorizecard {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unauthorizecard button {
    padding: 12px 30px;
}

.product-details-img img {
    margin: 35px auto 0;
}

.rfq-details-img {
    margin: 10px auto 0;
}

.pagination-remove .pagination-table {
    display: none;
}

// ######## Custom class ########
.fw-bold {
    font-weight: bold;
}

// .tooltip {
//     position: relative;
//     display: inline-block;
// }

.Exception_fill {
    float: right;
    position: relative;
    top: -34px;
    left: -20px;
    cursor: pointer;
}

.card-label-sec {
    .label-section-main {
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0.5rem;
        border-bottom: 1px solid rgb(241 245 249);
        margin: 0 0.5rem;

        label {
            margin: 0;
            font-size: 0.875rem !important;
            line-height: 1.5715 !important;
        }
    }

    .self-center {
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
        margin-bottom: 0;
        padding-left: 0rem;
        padding-right: 1rem;
        margin: 0 0.5rem;
        border-bottom: 1px solid rgb(241 245 249);
        font-size: 0.875rem;
        line-height: 1.5715;
        word-break: break-all;
    }

    .space-beetwin-sec {
        .label-section-main {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:last-child() {
                border: none;
            }
        }

        &.border-none {
            .label-section-main {
                border: none;
            }
        }
    }
}

.search-bar-input {
    input {
        padding-left: 35px;
    }
}

.view-product-table {
    .data-not-found-tbody {
        tr {
            td {
                text-align: center !important;
            }
        }
    }
}

thead {
    th {
        &:last-child {
            div {
                text-align: center !important;
            }
        }
    }
}

tr {
    td {
        &:last-child {
            text-align: center !important;
        }
    }
}

.table-tr-width {
    &.shopping-price-text {
        .price-formate {
            table {
                tbody {
                    tr {
                        td {
                            &:nth-child(6) {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table-tr-width {
    &.shopping-price-text {
        .UserActivity {
            table {
                tbody {
                    tr {
                        td {
                            &:nth-child(6) {
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}

.userActivityDetails {
    position: relative;
    left: 30px;
}

// .tooltip-text {
//     visibility: hidden;
//     position: absolute;
//     z-index: 2;
//     width: 100px;
//     color: white;
//     font-size: 12px;
//     background-color: #192733;
//     border-radius: 10px;
//     padding: 10px 15px 10px 15px;
// }

// .tooltip-text::before {
//     content: "";
//     position: absolute;
//     transform: rotate(45deg);
//     background-color: #192733;
//     padding: 5px;
//     z-index: 1;
// }

// .hover-text:hover .tooltip-text {
//     visibility: visible;
// }

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Define the tooltip text style */
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #000;
    /* Tooltip background color */
    color: #fff;
    /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    // bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

/* Show the tooltip on hover */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltipp {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Define the tooltip text style */
.tooltipp .tooltipptext {
    visibility: hidden;
    background-color: #000;
    /* Tooltip background color */
    color: #fff;
    /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    // bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

/* Show the tooltip on hover */
.tooltipp:hover .tooltipptext {
    visibility: visible;
    opacity: 1;
}

.tooltipp .tooltipptextUserActivity {
    visibility: hidden;
    background-color: #000;
    /* Tooltip background color */
    color: #fff;
    /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -10px;
    left: -110px;
    // left: 130px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.tooltipptextUserActivity::after {
    content: "";
    position: absolute;
    top: 21px;
    left: 100%;
    border-width: 9px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    transform: translateX(-50%) rotate(0deg);
}

/* Show the tooltip on hover */
.tooltipp:hover .tooltipptextUserActivity {
    visibility: visible;
    opacity: 1;
}

.margin-bottom-0 {
    margin-bottom: -2px !important;
}

#top {
    top: -40px;
    left: -50%;
}

#top::before {
    top: 80%;
    left: 45%;
}

#bottom {
    top: 25px;
    left: -50%;
}

#bottom::before {
    top: -5%;
    left: 45%;
}

#left {
    top: -8px;
    right: 120%;
}

#left::before {
    top: 35%;
    left: 94%;
}

#right {
    top: -8px;
    left: 120%;
}

#right::before {
    top: 35%;
    left: -2%;
}

.hover-text {
    position: relative;
    display: inline-block;
    margin: 40px;
    font-family: Arial;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer !important;
}

.fa-info-circle {
    font-size: 20px;
}

.IpDetails {
    display: flex !important;
    justify-content: space-between !important;
}

.Bars_details {
    position: relative;
    top: -5rem;
}

.activity_details {
    position: relative;
    top: 1rem;
}

.activity_details_flex {
    display: flex;
    gap: 8rem;
}

.activity_position {
    width: 500px;
}

.archive_color {
    color: #000 !important;
}

.useractivitydetails label {
    font-weight: 600;
}

.useractivitydetailsException label {
    font-weight: 600;
    white-space: pre;
}

.useractivitydetails,
.useractivitydetailsException {
    background-color: rgb(246, 246, 246);
    border: 1px solid rgb(219, 219, 219);
}

.shoppingCartdetails {
    background-color: #ffc1071a;
    font-weight: bold;
    font-size: 15px;
}

.shoppingCartdetails label {
    color: #1e293b;
    font-weight: 500;
}

.shoppingcart_details {
    position: relative;
    left: -0.7rem;
}

.productdashboard-card {
    box-shadow: 0px 5px 20px -10px #ffc107;
}

.productdashboard-card .useractivitydetails {
    background-color: #f8fbff;
    border: 1px solid #ebf2fc;
}

.fa-angle-down,
.fa-angle-up {
    font-size: 18px;
    font-weight: 800 !important;
    position: relative;
    top: 0.1rem;
    left: 1rem;
}

.UserDashboard,
.countDashboard {
    position: relative;
    top: 3rem;
}

.sidebar-childSubMenus {
    position: relative;
    left: -1rem;
}

.activity_details_flex {
    display: flex;
    gap: 8rem;
}

.ActivityDetails {
    display: flex;
    gap: 8rem;
}

.fa-file-import,
.fa-chart-line {
    color: #000;
}

.useractivitydetails,
.useractivitydetailsException span {
    word-break: break-all !important;
}

.ExceptionUpdate {
    display: flex;
    justify-content: end !important;
}

// .tooltip-container {
//     cursor: pointer;
//     position: relative;
//     display: inline-block;
// }

// .tooltip {
//     opacity: 0;
//     z-index: 99;
//     color: rgb(255, 255, 255);
//     width: auto;
//     display: block;
//     font-size: 11px;
//     padding: 5px 10px;
//     border-radius: 3px;
//     text-align: center;
//     text-shadow: 1px 1px 2px #111;
//     background: rgba(51,51,51,0.9);
//     border: 1px solid rgba(34,34,34,0.9);
//     box-shadow: 0 0 3px rgba(0,0,0,0.5);
//     -webkit-transition: all .2s ease-in-out;
//     -moz-transition: all .2s ease-in-out;
//     -o-transition: all .2s ease-in-out;
//     -ms-transition: all .2s ease-in-out;
//     transition: all .2s ease-in-out;
//     -webkit-transform: scale(0);
//     -moz-transform: scale(0);
//     -o-transform: scale(0);
//     -ms-transform: scale(0);
//     transform: scale(0);
//     position: absolute;
//     right: -125px;
//     bottom: 35px;
// }

// .tooltip:before,.tooltip:after {
//     content: '';
//     border-left: 10px solid transparent;
//     border-right: 10px solid transparent;
//     border-top: 10px solid rgba(51,51,51,0.9);
//     position: absolute;
//     bottom: -10px;
//     left: 45%;
// }

// .tooltip-container:hover .tooltip,a:hover .tooltip {
//     opacity: 1;
//     -webkit-transform: scale(1);
//     -moz-transform: scale(1);
//     -o-transform: scale(1);
//     -ms-transform: scale(1);
//     transform: scale(1);
// }
// .tooltips {
//     opacity: 0;
//     z-index: 99;
//     color: rgb(255, 255, 255);
//     width: auto;
//     display: block;
//     font-size: 11px;
//     padding: 5px 10px;
//     border-radius: 3px;
//     text-align: center;
//     text-shadow: 1px 1px 2px #111;
//     background: rgba(51,51,51,0.9);
//     border: 1px solid rgba(34,34,34,0.9);
//     box-shadow: 0 0 3px rgba(0,0,0,0.5);
//     -webkit-transition: all .2s ease-in-out;
//     -moz-transition: all .2s ease-in-out;
//     -o-transition: all .2s ease-in-out;
//     -ms-transition: all .2s ease-in-out;
//     transition: all .2s ease-in-out;
//     -webkit-transform: scale(0);
//     -moz-transform: scale(0);
//     -o-transform: scale(0);
//     -ms-transform: scale(0);
//     transform: scale(0);
//     position: absolute;
//     right: -69px;
//     bottom: 35px;
// }
// .tooltips:before,.tooltips:after {
//     content: '';
//     border-left: 10px solid transparent;
//     border-right: 10px solid transparent;
//     border-top: 10px solid rgba(51,51,51,0.9);
//     position: absolute;
//     bottom: -10px;
//     left: 44%;
// }

// .tooltips-container:hover .tooltips,a:hover .tooltips {
//     opacity: 1;
//     -webkit-transform: scale(1);
//     -moz-transform: scale(1);
//     -o-transform: scale(1);
//     -ms-transform: scale(1);
//     transform: scale(1);
// }
.grid-cols-dash-12 {
    grid-template-columns: repeat(15, minmax(0, 1fr));
}

.sync_count {
    padding: 9px 3px;
}

.loader {
    border: 5px solid #ffffff;
    border-radius: 50%;
    border-top: 5px solid #1e293b;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}

.productDashboard {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.productDashboard label {
    font-weight: 500;
    font-size: 20px;
}

.shadow-lg-productCard {
    box-shadow: 0 0px 14px -7px #ffc107 !important;
    cursor: pointer;
}

.product-form {
    position: relative;
    top: 2rem;
}

.Secondary-button {
    border: 1px solid var(--darkcommanColor) !important;
    color: var(--darkcommanColor);
}

.themeColor {
    color: var(--commanColoricon);
    font-weight: 600;
}

.themeColor-hover {
    color: var(--commanColoricon);
    font-weight: 600;
}

.themeColor-hover {
    .theme-color {
        color: var(--commanColoricon) !important;
    }
}

.text-darkthemeColor {
    i {
        color: var(--commanColoricon) !important;
    }
}

// .text-color-indigo-500{
//     color: #ff9900;
// }
.activeColor {
    background-color: rgb(32 168 60 / var(--tw-bg-opacity));
    color: #fff;
}

.Search-height {
    height: 2.5rem;
}

.DataImport-btn {
    float: right;
    margin-right: 1.5rem;
}

// .jsCon{
//     justify-content: end;
// }

// .jsCon{
//     justify-content: center;
// }
.colorSideMenu {
    font-size: 15px;
    color: #fff;
}

.colorSideMenu1 {
    // font-size: 17px;
    color: #fff;
}

.aCircule {
    height: 2rem;
}

.Dashbord-ml {
    margin-left: 1rem;
}

.menu-toggle::after {
    content: "";
    position: absolute;
    top: 50%;
    display: block;
    width: 0.42em;
    height: 0.42em;
    border: 1px solid;
    border-bottom: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);
}

.hightData {
    height: 2.5rem;
}

// Under-construction-sec Start
.under-construction-sec {
    height: calc(100vh - 125px);

    .card-body {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.custom-tab-section .user-details-section .widgets-section .center-div .card-title {
    padding: 7px 10px;
    background: #fff9e5;
    font-weight: 600;
    font-size: 105%;
    color: #000;
}

// Under-construction-sec End
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@media (max-width: 767px) {
    .mobile-logo {
        margin-bottom: 10px;
    }

    .mobile-overflow {
        overflow-y: auto;
    }
}

@media (max-width: 767px) {
    .btn-centerMV {
        margin-right: 1rem;
    }
}

@media (max-width: 767px) {
    .DataImport {
        margin-right: 6rem;
    }
}

// Accordion Section Start
.accordion-section {
    .db-helth-table {
        tr td:last-child {
            text-align: left !important;
        }
    }

    .accordion-header {

        // .brightness-100{
        //     filter: brightness(100);
        // }
        .button-title-action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px 0 0;
            background: var(--commanColorHover);

            button {
                width: 4%;
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .title-action {
                width: 96%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .wizard-title {
                    width: 55%;
                    font-size: 100%;
                }

                .wizard-count {
                    width: 45%;
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    h4 {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }

                    .active-inactive {
                        margin-right: 20px;
                        min-width: 100px;
                        text-align: center;

                        span {
                            font-size: 85%;
                            padding: 2px 10px 3px;
                            border-radius: 30px;
                            -webkit-border-radius: 30px;
                            -moz-border-radius: 30px;
                            -ms-border-radius: 30px;
                            -o-border-radius: 30px;
                            color: #fff;
                            margin: 0 10px;

                            &.inActiveColor {
                                background: #c00000;
                            }
                        }
                    }

                    .action-btn {
                        display: flex;
                        align-items: center;

                        button {
                            width: auto;
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            &.active-button {
                background: var(--commanColoricon);
                color: #000 !important;

                i {
                    color: #000 !important;
                }
            }
        }
    }
}

// Accordion Section End

.exportLink {
    label {
        width: 160px;
        display: flex;
        font-weight: bolder;
        // align-items: center;
    }
}

.exportLink .form-input {
    padding: 3px;
    height: 30px;
}


.card-count-part {
    .card-part {
        box-shadow: 0px 1px 20px -10px #464646 !important;
        background: #fff;

        &:hover {
            box-shadow: 0px 1px 20px -10px #464646 !important;
        }
    }
}

.pagination-input {
    border: none;
    text-align: center;
    width: 60px;
    height: 35px;
    background-color: #FFF9E5 !important;
    border-radius: 10px !important;
    border: 1px solid #000;
}

.pagination-input-disabled {
    border: none;
    text-align: center;
    width: 60px;
    height: 35px;
    background-color: #dbdbdb !important;
    border-radius: 10px !important;
}

.page-link {
    padding: 0;
    border-radius: 10px !important;
    position: relative;
    display: block;
    color: #000 !important;
    background-color: #FFF9E5 !important;
    width: 30px;
    height: 35px;
}

.page-link-active{
    padding: 0;
    border-radius: 10px !important;
    position: relative;
    display: block;
    color: #fff !important;
    background-color: #FFC107 !important;
    width: 30px;
    height: 35px;
}